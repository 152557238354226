<template>
  <div class="edit-password-container">
    <Title name='修改绑定手机'></Title>
    <div class="content">
        <div class="navigate">
            <div class="navigate-item" 
                v-for="item in navigateOptions" 
                :key="item.step" 
                :class="{selected: currentStep == item.step}">
                {{item.name}}
            </div>
        </div>
        <div v-if="currentStep === 1" class="form-box">
            <el-form 
                label-width="120px"
                :model="smsForm"
                ref="smsForm"
                :rules="smsFormRules" >
                <el-form-item label="新手机号码" prop="tel">
                    <el-input class="input-width" v-model="smsForm.tel" :maxlength="11" placeholder="请输入新手机号码" />
                </el-form-item>
                <el-form-item label="校验码" prop="code">
                    <el-input v-model="smsForm.code" style="width: 160px;margin-right: 8px;" placeholder="请输入校验码" />
                    <el-button @click="getCode" :disabled="smsBtnText !== '获取校验码'">{{smsBtnText}}</el-button>
                </el-form-item>
                <el-form-item label=" ">
                    <el-button type="primary" @click="nextStep">下一步</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-if="currentStep === 2" class="form-box">
            <el-form 
                label-width="140px"
                :model="pwdForm"
                ref="pwdForm"
                :rules="pwdFormRules">
                <el-form-item label="账号名">
                    <span>{{loginName}}</span>
                </el-form-item>
                <el-form-item label="登录密码" prop="pwd">
                    <el-input v-model="pwdForm.pwd" class="input-width" type="password" placeholder="请输入登录密码" />
                </el-form-item>
                <el-form-item label=" ">
                    <el-button type="primary" @click="updateTel">确认</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-if="currentStep === 3" class="message-box">
            <i class="icon-success el-icon-success"></i>
            <div class="title">修改绑定手机成功！</div>
            <el-button size="small" @click="toCorporateInfo">返回企业信息</el-button>
        </div>
    </div>
  </div>
</template>
<script>
import {
  getCode,
  verifyCode,
  updateTel,
  selectCountByTel
} from '../../api';
import Title from '../../component/Title';
import { regRule } from '@/utils/validate';
export default {
  components: {
    Title
  },
  data() {
    return {
      smsBtnText: '获取校验码',
      loginName: localStorage.getItem('loginName') || '',
      verifyCode: '',
      currentStep: 1,
      navigateOptions: [
        { step: 1, name: '1.输入新手机号' },
        { step: 2, name: '2.验证身份' },
        { step: 3, name: '3.修改成功' }
      ],
      smsForm: {
        tel: '',
        code: '',
        uuid: ''
      },
      pwdForm: {
          pwd: '',
      },
      smsFormRules: {
        tel: [{ required: true, pattern: regRule.regPhoneStrict, message: '请输入正确的手机号码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入校验码', trigger: 'blur' }],
      },
      pwdFormRules: {
        pwd: [{ required: true, message: '请输入登录密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    nextStep() {
        this.$refs.smsForm.validate(valid => {
            if (valid) {
                //校验验证码
                verifyCode({ 
                    code: this.smsForm.code,
                    uuid: this.smsForm.uuid
                }).then(res =>{
                    if(res.errno == 0){
                        this.currentStep = 2; 
                    } else {
                        this.$message.warning("校验码输入有误");
                    }
                })
            }
        })
    },
    getCode() {
        if (!this.smsForm.tel || !regRule.regPhoneStrict.test(this.smsForm.tel)) 
            return this.$message.warning('请输入正确手机号码');
        selectCountByTel({
            tel: this.smsForm.tel,
            scene: '2'
        }).then(res => {
            if (res.errno !== 0) return

            return getCode({
                phoneNum: this.smsForm.tel,
                scene: '9'
            })
        }).then(res =>{
            if (res.errno !== 0) return 
            this.smsForm.uuid = res.data.uuid;

            let timeNum = 60;
            let timer = setInterval(() => {
                timeNum--;
                this.smsBtnText = `${timeNum}s后重新发`;
                if (timeNum < 1) {
                    clearInterval(timer);
                    this.smsBtnText = '获取校验码';
                }
            }, 1000);
          if(res.data.ssm=='9'){
            this.$message.error('今日获取验证码数量已到达上限');
            return false;
          }
        })
    },
    updateTel() {
        this.$refs.pwdForm.validate(valid => {
            if (valid) {
                updateTel({
                    ...this.pwdForm,
                    ...this.smsForm
                }).then(res => {
                    if (res.errno == 0) {
                        this.currentStep = 3; 
                    }
                })
            }
        })
    },
    toCorporateInfo() {
        this.$router.push({
            path: '/User/CorporateInfo'
        });
    }
  }
}
</script>

<style lang="less">
.edit-password-container {
  background: #fff;
  min-height: 460px;
    
    .content {
        padding: 0 20px;
        box-sizing: border-box;

        .navigate {
            display: flex;
            padding: 0;
            margin: 0;
            overflow: hidden;
        

            &-item {
                position: relative;
                flex: 1;
                height: 34px;
                background: #ddd;
                // margin-right: 5px;
                text-align: center;
                line-height: 34px;
                margin-right: 24px;

                &::before {
                    position: absolute;
                    top: 0;
                    left: -17px;
                    width: 0;
                    height: 0;
                    content: '';
                    border-style: solid;
                    border-color: #ddd #ddd #ddd transparent;
                    border-width: 17px 0 17px 17px;
                }
                &::after {
                    position: absolute;
                    top: 0;
                    right: -17px;
                    width: 0;
                    height: 0;
                    content: '';
                    border-style: solid;
                    border-color: transparent transparent transparent #ddd;
                    border-width: 17px 0 17px 17px;
                }
                &:last-child {
                    margin-right: 0px;
                }
                &.selected {
                    color: #fff !important;
                    background-image: linear-gradient(to right, #ACB2CA, #33A2C5);
                }
                &.selected::before {
                    border-color: #ACB2CA #ACB2CA #ACB2CA transparent;
                }
                &.selected::after {
                    border-color: transparent transparent transparent #33A2C5;
                }
            }
        }
        .form-box {
            margin: 40px auto;
            width: 460px;

            .input-width {
                margin-right: 8px;
                width: 200px;
            }
        }
        .message-box {
            margin-top: 80px;
            text-align: center;

            .icon-success {  
                font-size: 70px;       
                color: rgb(11, 189, 11);   
            }
            .title {
                margin: 10px 0 16px;
                font-size: 16px;
                font-weight: bold;
                color: #333;   
            }
            .description {
                margin-bottom: 24px;
                font-size: 14px;
                color: #333;  
            }
        }
    }
}
</style>